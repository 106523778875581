import * as React from "react";
import Layout from "../components/atoms/layout";

const Tips = () => {
  return (
    <Layout>
      <h1>Tips</h1>
    </Layout>
  );
};

export default Tips;
